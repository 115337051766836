<template>
  <main class="main">
    <ul class="library container">
      <li v-for="(item, index) in library" :key="index" class="library-item">
        <a :href="item.link" target="_blank" style="min-height: 400px;">
          <img v-if="item.type === 'library'" class="library-image library-main" :src="item.image">
          <div class="library-main" v-else>
            <iframe  width="auto" height="auto" :src="item.link" frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen> 
            </iframe>
          </div>
          <span class="tag">
            {{ item.tag }}
          </span>
          <p>
            {{ item.text }}
          </p>
        </a>
      </li>
    </ul>
    <HeroSimple v-if="lib" v-bind="lib"></HeroSimple>
  </main>
</template>

<script>
import slides from '../../public/json/decret-tertiaire-slides.json'
import lib from '../../public/json/decret-tertiaire-librairie.json'
import HeroSimple from "@/components/HeroSimple.vue";
export default {
  components: {
    HeroSimple,
  },
  metaInfo: {
    meta: [{
      name: 'description',
      content: 'Découvrez de nombreuses ressources utiles sur le décret tertiaire. Elles sont gratuites, n’hésitez pas à les consulter !'
    }]
  },
  computed: {
    library() {
      return slides
    },
    lib() {
      return lib
    }

  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";

.library {
  display: flex;
  flex-wrap: wrap;

  &-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 340px;    
  }

  &-image {
    width: 240px;
  }

  &-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(25% - 16px);
    min-width: 240px;
    margin: 0 8px 48px 8px;

    @media (max-width: 768px) {
      width: 300px;
      flex-wrap: wrap;
    }

    iframe {
      max-width: 100%;
      min-height: 200px;
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      height: 100%;
      transition: all .1s ease-in;

      &:hover {
        transform: scale(1.02);

        & p {
          color: $green;
        }
      }
    }

    span {
      margin: 8px auto 16px 16px;
      border: 1px solid $black;
    }
    p {
      line-height: 1.2;
      font-size: 18px;
      margin: 8px 16px 16px 16px; 
      color: $black;
    }
  }
}

</style>