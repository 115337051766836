<template>
  <section>
    <div class="container">
      <h3 v-html="title" />
      <h4 v-html="text" />
      <a v-if="link" :href="link" target="_blank">{{ text_link }} <img src="/img/download.svg" alt=""></a>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    text_link: {
      type: String,
    },
    link: {
      type: String,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";

section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 32px;
  background-color: $black;
  color: white;

  h3, h4, a, svg, h4 a {
    color: white;
    fill: $green;
  }

  h3 {
    font-size: 48px;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 24px;
  }

  h4 {
    font-size: 16px;
    line-height: 1.1;
  }

  a {
    display: flex;
    align-items: center;
    margin-top: 42px;
    font-weight: 900;

    img {
      width: 24px;
      margin-left: 12px;
    }
  }
}
</style>

<style lang="scss">

section .container h4 a {
  color: white!important;
  font-weight: 600!important;
  text-decoration: underline!important;
}
</style>